<template>
  <div class="page">
    <div class="title">问诊订单</div>
    <div class="tab-area">
      <div class="tab" :class="active == 0 ? 'tab-active' : ''" @click="active = 0">
        待确认
      </div>
      <div class="tab" :class="active == 1 ? 'tab-active' : ''" @click="active = 1">
        待面诊
      </div>
      <div class="tab" :class="active == 2 ? 'tab-active' : ''" @click="active = 2">
        进行中
      </div>
      <div class="tab" :class="active == 3 ? 'tab-active' : ''" @click="active = 3">
        已完成
      </div>
    </div>
    <b-overlay class="list" :show="show" v-show="total > 0">
      <b-list-group>
        <b-list-group-item
          v-for="(item, i) in list"
          :key="i"
          class="d-flex justify-content-between align-items-center"
          @click="openinfo(item)"
        >
          <div style="display: flex">
            <div style="margin-right: 20px">
              <img class="avatar" :src="avatar" />
            </div>
            <div>
              <div class="nickname">{{ item.userinfo.nickname }}</div>
              <div class="desc">{{ item.types }} | {{ item.part }}</div>
            </div>
          </div>
          <b-button pill variant="primary" size="sm">接受</b-button>
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
    <empty v-show="total == 0"></empty>
    <b-pagination
      v-show="total > params.limit"
      :total-rows="total"
      :per-page="params.limit"
      v-model="params.page"
    ></b-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      params: {
        page: 1,
        limit: 6,
        show_status: 0,
      },
      list: [],
      total: 0,
      show: false,
    };
  },
  watch: {
    active: function (val) {
      this.params.show_status = val;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 500);
      this.$request.orderslist(this.params).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          data.items.forEach((item) => {
            switch (Number(item.type)) {
              case 0:
                item.types = "图文咨询";
                break;
              case 1:
                item.types = "语音咨询";
                break;
              case 2:
                item.types = "视频咨询";
                break;
            }
            if (item.userinfo.avatar) {
              item.avatar = this.$host + item.userinfo.avatar;
            }
          });
          this.list = data.items;
          this.total = data.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    openinfo(item) {
      this.$router.push({
        path: "/doctorInfo/order/info",
        query: {
          id: item.id,
          name: item.userinfo.nickname,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 910px;
  padding: 10px 30px;
  min-height: 620px;
  background: #ffffff;
}
.title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: left;
  height: 36px;
  line-height: 36px;
}

.tab-area {
  width: 512px;
  height: 40px;
  background: #f6f4f4;
  display: flex;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  margin: 5px 0;
  .tab {
    width: 128px;
  }
  .tab:hover {
    cursor: pointer;
  }
  .tab-active {
    background: #fd9852;
    color: #fff;
  }
}
.list {
  margin: 20px 0;
  min-height: 480px;
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .nickname {
    font-size: 20px;
    height: 36px;
    line-height: 36px;
  }
  .desc {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
  }
}
</style>
